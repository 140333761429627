.error {
  background-color: #FF748D;
  border-radius: 8px;
  width: 300px;
}

.success {
  background-color: #43a0f7;
  border-radius: 8px;
  width: 300px;
}

.warnning {
  background-color: #f8eb79;
  border-radius: 8px;
  width: 300px;
}

.info {
  background-color: #2980b9;
  border-radius: 8px;
  width: 300px;
}